import React, { useState } from 'react'
import { Fieldset, FormField } from 'components/FormControls'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'
import UpdateBusinessDetailsModal from 'modules/seller-hub/settings/components/UpdateBusinessDetailsModal'
import formatTaxNumber from 'lib/format-tax-number'
import { BuyerAttributesArguments, SellerAttributesArguments } from '../../../../../__generated__/globalTypes'
import { useFormikContext } from 'formik'

import styles from './BusinessDetailsFields.module.css'
import classNames from 'classnames/bind'

interface BusinessDetailsFieldsProps {
  disableFields?: boolean | 'taxNumber' | 'entityName'
  className?: string
  taxName?: string
  showEditButton?: boolean
}

const cx = classNames.bind(styles)
const BusinessDetailsFields = ({
  disableFields = false,
  className,
  taxName = 'ABN',
  showEditButton = false
}: BusinessDetailsFieldsProps) => {
  const [showUpdateBusinessModal, setShowUpdateBusinessModal] = useState<boolean>(false)
  const { setFieldValue, values } = useFormikContext<BuyerAttributesArguments & SellerAttributesArguments>()

  const handleModalClose = async () => {
    if (showUpdateBusinessModal) setShowUpdateBusinessModal(false)
  }

  return (
    <Fieldset title="Business Details" className={cx(className, styles.fieldset)}>
      <FormField
        type="text"
        name="taxNumber"
        kind="primary"
        title={taxName}
        disabled={disableFields === true || disableFields === 'taxNumber'}
        taxCountry={
          taxName === 'ABN'
            ? 'AU'
            : taxName === 'NZBN'
            ? 'NZ'
            : taxName === 'VAT'
            ? 'GB'
            : taxName === 'EIN'
            ? 'US'
            : ''
        }
      />
      <FormField
        type="text"
        name="entityName"
        kind="primary"
        disabled={disableFields === true || disableFields === 'entityName'}
        title={
          <>
            Business Name*
            <Tooltip iconSize={16}>Business name that your {taxName} is registered under.</Tooltip>
          </>
        }
      />
      {showEditButton ? (
        <Button
          className={styles.editBusinessDetailsButtion}
          onClick={() => setShowUpdateBusinessModal(true)}
          icon="edit"
          iconPosition="left">
          Edit Business Details
        </Button>
      ) : null}
      <UpdateBusinessDetailsModal
        seller={{
          country: values.accountAddressAttributes?.country ?? values.billingAddressAttributes?.country ?? '',
          taxNumber: values.taxNumber ?? '',
          taxName: taxName,
          entityName: values.entityName ?? ''
        }}
        onExit={handleModalClose}
        handleSubmit={({ taxNumber, businessName, country }) => {
          setFieldValue('taxNumber', formatTaxNumber({ taxNumber, country }))
          setFieldValue('entityName', businessName)
          setShowUpdateBusinessModal(false)
        }}
        isOpen={showUpdateBusinessModal}
      />
    </Fieldset>
  )
}

export default BusinessDetailsFields
