import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { E164Number } from 'libphonenumber-js/core'

import { Fieldset, FieldsetRow, FormField } from 'components/FormControls'
import { AddressValueProps } from 'components/AddressAutocomplete/AddressAutocomplete'
import { locationTypeOptions } from 'components/AddressPickerForm/AddressPickerFormFields'
import { CreateBuyerFormArguments } from '../CreateBuyerForm/useCreateBuyerForm'
import { SellerAttributesArguments, AccountableTypeEnum } from '../../../../../__generated__/globalTypes'

import styles from './AccountAddressFields.module.css'

interface AccountAddressFieldsProps {
  className?: string
  accountType?: AccountableTypeEnum
  defaultCountry?: string
  isOnboarding?: boolean
}

const AccountAddressFields = ({
  accountType = AccountableTypeEnum.BUYER,
  className,
  defaultCountry,
  isOnboarding = false
}: AccountAddressFieldsProps) => {
  const { setFieldValue, values, touched } = useFormikContext<CreateBuyerFormArguments & SellerAttributesArguments>()

  // NOTE: Thinking of also restricting the country for the buyer, since they are also required to choose 'country' for business details.
  const restrictSellerCountry = accountType === AccountableTypeEnum.SELLER ? defaultCountry : undefined

  const copyShippingFieldToBillingField = (name: string, fieldName: string, value: string) => {
    if (
      isOnboarding &&
      values.usesShippingForBillingAddress &&
      accountType === AccountableTypeEnum.BUYER &&
      name === 'additionalShippingAddressAttributes'
    ) {
      setFieldValue(`additionalBillingAddressAttributes.${fieldName}`, value)
    }
  }

  const copyShippingToBillingAddress = () => {
    setFieldValue('billingAddressAttributes', values.shippingAddressAttributes)
    setFieldValue('additionalBillingAddressAttributes', values.additionalShippingAddressAttributes)
  }

  const clearBillingAddress = () => {
    setFieldValue('billingAddressAttributes', null)
    setFieldValue('additionalBillingAddressAttributes', {
      name: null,
      contact: null,
      locationType: null,
      nickname: null,
      email: null,
      phone: null
    })
  }

  const additionalAddressFields = (name: string) => (
    <>
      <FormField
        type="text"
        name={`${name}.name`}
        kind="primary"
        title="Business / Store Name*"
        className={styles.formFieldWrapper}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          copyShippingFieldToBillingField(name, 'name', e.target.value)
        }
      />
      <FieldsetRow>
        <FormField
          type="select"
          name={`${name}.locationType`}
          kind="primary"
          title="Location Type"
          placeholder="Select type"
          options={locationTypeOptions}
          className={styles.left}
          onChange={(e: { value: string; label: string }) =>
            copyShippingFieldToBillingField(name, 'locationType', e.value)
          }
        />
        <FormField
          type="text"
          name={`${name}.nickname`}
          kind="primary"
          title="Address Nickname"
          className={styles.right}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            copyShippingFieldToBillingField(name, 'nickname', e.target.value)
          }
        />
      </FieldsetRow>
      <FieldsetRow>
        <FormField
          type="text"
          name={`${name}.contact`}
          kind="primary"
          title="Contact Name*"
          className={styles.left}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            copyShippingFieldToBillingField(name, 'contact', e.target.value)
          }
        />
        <FormField
          type="text"
          name={`${name}.email`}
          kind="email"
          title="Contact Email*"
          className={styles.right}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            copyShippingFieldToBillingField(name, 'email', e.target.value)
          }
        />
      </FieldsetRow>
      <FormField
        type="tel"
        name={`${name}.phone`}
        kind="primary"
        title="Telephone*"
        defaultCountry={defaultCountry || 'AU'}
        onChange={(value: E164Number) => copyShippingFieldToBillingField(name, 'phone', value)}
      />
    </>
  )

  // Prefill `phone` for shipping and billing address
  // There is an issue with TelephoneInput's onChange and onBlur which are not triggered when the value is set so for now use useEffect hooks to set the value automatically.
  useEffect(() => {
    if (accountType === AccountableTypeEnum.BUYER && isOnboarding && values.phone !== null) {
      if (
        !touched.additionalShippingAddressAttributes?.phone &&
        values.additionalShippingAddressAttributes?.phone == null
      ) {
        setFieldValue('additionalShippingAddressAttributes.phone', values?.phone)
      }

      if (
        !touched.additionalBillingAddressAttributes?.phone &&
        values.additionalBillingAddressAttributes?.phone == null
      ) {
        setFieldValue('additionalBillingAddressAttributes.phone', values?.phone)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.phone,
    values.additionalShippingAddressAttributes?.phone,
    values.additionalBillingAddressAttributes?.phone
  ])

  return (
    <Fieldset title={accountType === AccountableTypeEnum.BUYER ? 'Addresses' : ''} className={className}>
      {accountType === AccountableTypeEnum.SELLER ? (
        <FormField
          type="tel"
          name="phone"
          kind="primary"
          title="Phone Number*"
          defaultCountry={defaultCountry ?? 'AU'}
        />
      ) : null}
      <FormField
        type="addressAutocomplete"
        name={accountType === AccountableTypeEnum.BUYER ? 'shippingAddressAttributes' : 'accountAddressAttributes'}
        kind="primary"
        placeholder="Search for your address"
        title={accountType === AccountableTypeEnum.BUYER ? 'Shipping Address*' : 'Address*'}
        onChange={(address: AddressValueProps) => {
          if (accountType === AccountableTypeEnum.BUYER && values.usesShippingForBillingAddress) {
            setFieldValue('billingAddressAttributes', address)
          }
        }}
        restrictCountries={restrictSellerCountry}
      />

      {accountType === AccountableTypeEnum.BUYER ? (
        <>
          {/* Show only during Buyer onboarding - if usesShippingForBillingAddress === TRUE */}
          {isOnboarding && additionalAddressFields('additionalShippingAddressAttributes')}

          <FormField
            type="checkbox"
            name="usesShippingForBillingAddress"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              event.target.checked ? copyShippingToBillingAddress() : clearBillingAddress()
            }>
            Use shipping address for billing address
          </FormField>

          {!values.usesShippingForBillingAddress ? (
            <>
              <FormField
                type="addressAutocomplete"
                name="billingAddressAttributes"
                kind="primary"
                placeholder="Search for your address"
                title="Billing Address*"
                className={styles.formFieldWrapper}
              />

              {/* Show only during Buyer onboarding - if usesShippingForBillingAddress === FALSE */}
              {isOnboarding && additionalAddressFields('additionalBillingAddressAttributes')}
            </>
          ) : null}
        </>
      ) : null}
    </Fieldset>
  )
}

export default AccountAddressFields
