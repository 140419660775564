import React, { useState } from 'react'

import ModalContainer from 'components/ModalContainer'
import BusinessNamePanel from '../BusinessNamePanel'

import { ModalDialogProps } from 'components/ModalDialog/ModalDialog'
import SearchForBusinessNumberForm from 'modules/signup/components/SearchForBusinessNumberForm'
import SelectBusinessNumberResultForm from 'modules/signup/components/SelectBusinessNumberResultForm'
import { GetBusinessDetailsFormData_currentSeller } from '../BusinessDetailsForm/graphql/__generated__/GetBusinessDetailsFormData'
import { SearchForBusinessNumberFormAttributes } from 'modules/signup/components/SearchForBusinessNumberForm/useSearchForBusinessNumberForm'
import { CheckAccountDetails_accountVerification } from 'modules/signup/components/SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'

import styles from './UpdateBusinessDetailsModal.module.css'

type UpdateBusinessDetailsModalProps = {
  seller?:
    | GetBusinessDetailsFormData_currentSeller
    | {
        taxNumber: string
        taxName: string
        entityName: string
        country: string
      }
  handleSubmit?: ({
    taxNumber,
    businessName,
    country
  }: {
    businessName: string
    taxNumber: string
    country?: string
  }) => void
} & ModalDialogProps

const UpdateBusinessDetailsModal = ({ seller, handleSubmit, ...rest }: UpdateBusinessDetailsModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [businessSearchParams, setBusinessSearchParams] = useState<{
    searchData: SearchForBusinessNumberFormAttributes
    results: CheckAccountDetails_accountVerification[]
  }>()

  const getForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <SearchForBusinessNumberForm
            onSuccess={(businessData, formValues) => {
              setBusinessSearchParams({
                searchData: formValues,
                results: businessData
              })
              // Skip to step 3 for US based businesses
              if (formValues.country === 'US') {
                setCurrentStep(3)
              } else {
                setCurrentStep(prevStep => prevStep + 1)
              }
            }}
            initialFieldValues={
              businessSearchParams?.searchData ?? {
                country: seller?.country ?? 'AU',
                search: seller?.taxNumber || seller?.entityName || ''
              }
            }
          />
        )
      case 2:
        return (
          <SelectBusinessNumberResultForm
            onSuccess={selectedBusinessNumber => {
              handleSubmit &&
                handleSubmit({
                  country: businessSearchParams?.searchData.country,
                  taxNumber: selectedBusinessNumber.business,
                  businessName:
                    businessSearchParams?.results.filter(
                      business => business.taxNumber === selectedBusinessNumber.business
                    )[0].businessName ??
                    seller?.entityName ??
                    ''
                })
              setCurrentStep(1)
            }}
            onGoBack={() => setCurrentStep(prevStep => prevStep - 1)}
            businessData={businessSearchParams?.results ?? []}
            searchParam={businessSearchParams?.searchData.search ?? ''}
          />
        )
      case 3:
        return (
          <BusinessNamePanel
            onSuccess={data => {
              handleSubmit &&
                handleSubmit({
                  country: businessSearchParams?.searchData.country,
                  taxNumber: businessSearchParams?.searchData.search ?? '',
                  businessName: data.entityName
                })
              setCurrentStep(1)
            }}
            onGoBack={() => setCurrentStep(1)}
            taxName={seller?.taxName ?? 'tax number'}
            initialFieldValues={{
              entityName: seller?.entityName || ''
            }}
          />
        )
    }
  }

  return (
    <ModalContainer {...rest}>
      <div className={styles.modalContent}>{getForm()}</div>
    </ModalContainer>
  )
}

export default UpdateBusinessDetailsModal
