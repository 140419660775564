import React from 'react'
import { useQuery } from '@apollo/client'
import { useFormikContext } from 'formik'
import capitalize from 'lodash.capitalize'

import Tooltip from 'components/Tooltip'
import { Fieldset, FormField } from 'components/FormControls'

import GET_BUSINESS_CATEGORIES from './graphql/GetBusinessCategories.graphql'
import { GetBusinessCategories } from './graphql/__generated__/GetBusinessCategories'

import { slugifyString } from 'components/SlugPicker/SlugPicker'
import { CreateBuyerFormArguments } from '../CreateBuyerForm/useCreateBuyerForm'
import { SellerAttributesArguments, AccountableTypeEnum } from '../../../../../__generated__/globalTypes'

interface AccountDetailsFieldsProps {
  className?: string
  accountType?: AccountableTypeEnum
  defaultCountry?: string
  isOnboarding?: boolean
}

const AccountDetailsFields = ({
  accountType = AccountableTypeEnum.BUYER,
  className,
  defaultCountry,
  isOnboarding = false
}: AccountDetailsFieldsProps) => {
  const { setFieldValue, values, touched } = useFormikContext<CreateBuyerFormArguments & SellerAttributesArguments>()

  const { data } = useQuery<GetBusinessCategories>(GET_BUSINESS_CATEGORIES, {
    skip: accountType !== AccountableTypeEnum.BUYER
  })

  const businessCategoryOptions = (data?.businessCategoryTaxonomies ?? []).map(item => ({
    label: item.fullPath,
    value: item.id
  }))

  return (
    <Fieldset title={`${capitalize(accountType)} Account Details`} className={className}>
      <FormField
        type="text"
        name="displayName"
        kind="primary"
        title="Display Name*"
        placeholder="Your brand or ‘trading as’ name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (accountType === AccountableTypeEnum.SELLER) {
            setFieldValue('slug', slugifyString(e.target.value))
          }
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (accountType === AccountableTypeEnum.BUYER && isOnboarding) {
            if (values.additionalShippingAddressAttributes.name == null) {
              setFieldValue('additionalShippingAddressAttributes.name', e.target.value)
            }
            if (values.additionalBillingAddressAttributes.name == null) {
              setFieldValue('additionalBillingAddressAttributes.name', e.target.value)
            }
          }
        }}
      />
      {accountType === AccountableTypeEnum.BUYER ? (
        <>
          <FormField
            type="textarea"
            name="description"
            kind="primary"
            title="About Your Business*"
            placeholder="A short description of your business"
            maxLength={500}
            rows={3}
            description="This will be shown to Sellers when you request access to their wholesale prices. Provide a description of your business and the products that you’re interested in."
            descriptionPosition="top"
          />
          <FormField
            type="fuzzyfinder"
            rows={3}
            kind="primary"
            name="businessCategoryTaxonomyId"
            title="Business Category*"
            placeholder="Search business categories"
            debounceWait={300}
            options={businessCategoryOptions}
            fuzzyFinderProps={{
              showId: false
            }}
          />
          <FormField
            type="email"
            name="email"
            kind="primary"
            title="Business Email*"
            description="This email will be visible to Wholesalers, and can be different from your login in email if you prefer"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (
                values.additionalShippingAddressAttributes.email == null &&
                !touched.additionalShippingAddressAttributes?.email &&
                isOnboarding
              ) {
                setFieldValue('additionalShippingAddressAttributes.email', e.target.value)
              }
              if (
                values.additionalBillingAddressAttributes.email == null &&
                !touched.additionalBillingAddressAttributes?.email &&
                isOnboarding
              ) {
                setFieldValue('additionalBillingAddressAttributes.email', e.target.value)
              }
            }}
          />
          <FormField
            type="tel"
            name="phone"
            kind="primary"
            title={accountType === AccountableTypeEnum.BUYER ? 'Business Phone*' : 'Phone Number*'}
            defaultCountry={defaultCountry ?? 'AU'}
          />
        </>
      ) : (
        <FormField
          type="slugpicker"
          kind="primary"
          autoComplete="off"
          title={
            <>
              Your Seller page URL
              <Tooltip iconSize={16}>
                Enter a URL that can be shared with your buyers to take them straight to your seller page.
              </Tooltip>
            </>
          }
          prefix="fieldfolio.com/"
          placeholder="your-seller-page-url"
          name="slug"
        />
      )}
    </Fieldset>
  )
}

export default AccountDetailsFields
